import {BrowserRouter} from 'react-router-dom';
import {Layout, App as AntApp} from 'antd';
import './App.css';
import './custom.css'
import './bootstrap-spacing.css'
import AppLayout from "./Components/Layout";
import AppRoutes from "./Routes";
import {useEffect} from "react";
import {getAppToken} from "./Helpers/jwt-helper";
import {getSiteConfig} from "./Helpers/config-helper";
import VersionChecker from "./Components/VersionChecker";

const {Content} = Layout;

function App() {
    const APP_VERSION = '1.4.1';

    useEffect(() => {
        console.log("Thanh Toan Dien Tu - Version " + APP_VERSION);
        getAppToken();
        getSiteConfig();
    }, [])

    return (
        <AntApp>
            <BrowserRouter>
                <AppLayout>
                    <Content>
                        <AppRoutes/>
                    </Content>
                </AppLayout>
            </BrowserRouter>
            <VersionChecker appVersion={APP_VERSION}/>
        </AntApp>
    );
}

export default App;
