import {Col, Form, Input, InputNumber, Modal, Row, Select} from "antd";
import {useState} from "react";
import api from "../../Helpers/api-helper";
import {notify} from "../../Helpers/toast-helper";
import {propertyOf} from "lodash";

const {Search, TextArea} = Input;

const PaymentLineInput = ({open, setOpen, lineData, onAddData, config}) => {
    const expenseTypes = [
        {
            label: "Thuế nhập khẩu",
            value: 'taxImport'
        },
        {
            label: "Thuế VAT",
            value: 'taxVat'
        },
        {
            label: "Phí hải quan",
            value: 'customFee'
        },
        {
            label: "Cước biển",
            value: 'seaFee'
        },
        {
            label: "Các loại chi phí khác",
            value: 'others'
        }
    ]

    const [form] = Form.useForm()
    const [poInputDisabled, setPoInputDisabled] = useState(false)
    const [poSearchLoading, setPoSearchLoading] = useState(false)

    const [currency, setCurrency] = useState('')

    const getPOInformation = async (poCode) => {
        if (!poCode || poCode.trim().length < 14) {
            notify('error', 'Mã PO không hợp lệ')
            return
        }
        setPoSearchLoading(true)

        api.get(`/odoo/po/${poCode}`).then(response => {
            if (response) {
                if (response.custom_state !== 'approved') {
                    notify('error', 'PO chưa được phê duyệt hoặc đã hoàn tất thanh toán')
                    form.resetFields()
                    return
                }

                //Determine the vat percentage based on amount_total and amount_untaxed
                const vat = (response.amount_total - response.amount_untaxed) / response.amount_untaxed * 100

                form.setFieldsValue({
                    amount: calcPercentage(response.amount_total, form.getFieldValue('payment_percentage') || 100),
                    total_amount: response.amount_total,
                    vat: vat,
                    currency_code: response.currency_id[1],
                    description: response.description,
                    occasion_code: response?.occasion_code_id?.length > 0 ?  response.occasion_code_id[1] : ''
                })

                setCurrency(response.currency_id[1])
            }
        }).finally(() => {
            setPoSearchLoading(false)
        })
    }

    const CurrencyOptions = (options) => {
        if (!options)
            return null

        return (
            <Form.Item
                name="currency_code"
                style={{marginBottom: 0}}
                initialValue="VND"
            >
                <Select
                    style={{width: 80, maxHeight: '30px'}}
                    onChange={(value) => setCurrency(value)}
                    value={currency}
                >
                    {options.map((item, index) => (
                        <Select.Option key={index} value={item.currency}>
                            {item.currency}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }

    const handleExpenseTypeChange = (value) => {
        if (value !== 'others') {
            setPoInputDisabled(true)
            form.setFieldValue('poCode', '')
        } else {
            setPoInputDisabled(false)
        }
    }

    const handleClose = () => {
        form.resetFields();
        setOpen(false);
        setCurrency('')
        setPoInputDisabled(false)
    }

    const handleOk = () => {
        form.validateFields().then(values => {
            onAddData(values)
            handleClose()
        }).catch(error => {
            console.log(error)
        })
    }

    const calcAmount = () => {
        //Remove any non-numeric characters
        const quantity = form.getFieldValue('quantity')
        const price = form.getFieldValue('price')
        const vat = form.getFieldValue('vat')

        //Only calculate if both quantity and price are not empty and is a number
        if (!quantity || !price || isNaN(quantity) || isNaN(price)) {
            return form.setFieldsValue({
                total_amount: null,
                amount: null
            })
        }

        //If negative numbers, return
        if (quantity < 0 || price < 0) {
            return form.setFieldsValue({
                total_amount: null,
                amount: null
            })
        }

        const amount = Math.floor(quantity * price * (1 + vat / 100))
        form.setFieldsValue({
            total_amount: isNaN(amount) ? null : amount,
            amount: calcPercentage(amount, null, form.getFieldValue('currency_code')),
        });
    }

    const calcPercentage = (pAmount, pPercentage, currency_code = 'VND') => {
        //Calculate amount after payment percentage change
        //Round it to nearest 2 decimal places
        const percent = pPercentage || form.getFieldValue('payment_percentage')
        const amount = pAmount || form.getFieldValue('total_amount')

        const digits = form.getFieldValue('currency_code') === 'VND' ? 0 : 2
        let newAmount = Number(((pAmount || amount) * Number(percent) / 100).toFixed(digits))

        newAmount = isNaN(newAmount) ? null : newAmount

        form.setFieldValue('amount', newAmount)
        return newAmount
    }

    return (
        <Modal
            title={lineData ? 'Sửa thông tin chi phí' : 'Thêm thông tin chi phí'}
            open={open}
            onCancel={handleClose}
            maskClosable={false}
            cancelText="Bỏ qua"
            okText={lineData ? 'Lưu thay đổi' : 'Thêm chi phí'}
            onOk={handleOk}
        >
            <Form
                className="mt-4"
                labelCol={{
                    span: 6,
                    style: {
                        textAlign: 'left'
                    }
                }}
                wrapperCol={{
                    span: 18,
                }}
                form={form}
                layout="horizontal"
            >
                <Form.Item
                    label="Loại chi phí"
                    name="expense_type"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng chọn loại chi phí'
                        }
                    ]}
                    initialValue="others"
                >
                    <Select
                        options={expenseTypes}
                        //onChange={handleExpenseTypeChange}
                    />
                </Form.Item>

                <Form.Item
                    label="Mã PO chi phí"
                    name="poCode"
                >
                    <Search
                        loading={poSearchLoading}
                        disabled={poInputDisabled}
                        placeholder="Ví dụ: PLK2323000001"
                        onSearch={getPOInformation}
                        allowClear
                    />
                </Form.Item>

                <Form.Item
                    name="quantity"
                    label="Số lượng"
                >
                    <InputNumber
                        className="w-100"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        onChange={calcAmount}
                    />
                </Form.Item>

                <Form.Item
                    name="price"
                    label="Đơn giá"
                >
                    <InputNumber
                        className="w-100"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        onChange={calcAmount}
                    />
                </Form.Item>

                <Form.Item
                    name="vat"
                    label="Thuế VAT"
                >
                    <Select
                        onChange={calcAmount}
                        defaultValue={0}
                    >
                        <Select.Option value={0}>0%</Select.Option>
                        <Select.Option value={5}>5%</Select.Option>
                        <Select.Option value={8}>8%</Select.Option>
                        <Select.Option value={10}>10%</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="total_amount"
                    label="Tổng cộng"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập số tiền cần thanh toán'
                        }
                    ]}
                >
                    <InputNumber
                        className="w-100"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        onChange={(value) => calcPercentage(value, null, form.getFieldValue('currency_code'))}
                    />
                </Form.Item>

                <Form.Item
                    label="Tỉ lệ thanh toán"
                    name="payment_percentage"
                    initialValue={100}
                >
                    <InputNumber
                        className="w-100"
                        addonAfter={'%'}
                        min={1}
                        max={100}
                        onBlur={(e) => {
                            //If current value is not a number or blank, set to 100
                            if (isNaN(e.target.value) || e.target.value === '') {
                                form.setFieldValue('payment_percentage', 100)
                            }

                            calcPercentage()
                        }}
                        onChange={(value) => {
                            calcPercentage()
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Thành tiền"
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập số tiền'
                        }
                    ]}
                >
                    <InputNumber
                        readOnly
                        disabled
                        className="w-100"
                        addonAfter={CurrencyOptions(config.rates)}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        //onChange={(value) => form.setFieldValue('amount', calcPercentage(value))}
                    />
                </Form.Item>

                <Form.Item
                    label="Diễn giải"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập diễn giải',
                        }
                    ]}
                >
                    <TextArea
                        rows={3}
                        style={{
                            resize: 'none'
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Ghi chú"
                    name="note"
                >
                    <TextArea
                        //No resize
                        rows={3}
                        style={{
                            resize: 'none'
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="currency_code"
                    className="d-none"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="occasion_code"
                    className="d-none"
                >
                    <Input />
                </Form.Item>
            </Form>

        </Modal>
    )
}

export default PaymentLineInput;
