import {Button, Flex, Input, Modal, Space} from "antd";
import "./PrintForm.css"
import dayjs from 'dayjs'
import {useEffect, useRef, useState} from "react";
import {PaymentLineTable} from "./helper";
import {readNumber} from "../../Helpers/number-helper";
import {useReactToPrint} from 'react-to-print';
import {BorderTopOutlined, CloseOutlined, PrinterOutlined,} from "@ant-design/icons";
import {createGlobalStyle} from "styled-components";
import PageMargin from "./PageMargin";
import Barcode from 'react-barcode';


const FormPrintPreview = ({open, setOpen, data, lineData, showPrintButton = false}) => {

    const [marginPlaceholder, setMarginPlaceholder] = useState(1.2)

    const [customMarginOpen, setCustomMarginOpen] = useState(false)
    const defaultMargin = {
        top: marginPlaceholder, //add 1cm to hide header
        right: 1,
        bottom: marginPlaceholder, //add 1cm to hide footer
        left: 1.5
    }
    const [printMargin, setPrintMargin] = useState(defaultMargin)

    const PrintStyle = createGlobalStyle`
        @page {
            margin-right: ${printMargin?.right}cm;
            margin-left: ${printMargin?.left}cm;
        }
    `;

    const printRef = useRef(null)
    const [date, setDate] = useState({
        day: dayjs().format("DD"),
        month: dayjs().format("MM"),
        year: dayjs().format("YYYY")
    })

    const defaultPersonnel = {
        name: '',
        department: ''
    }

    const [personnel, setPersonnel] = useState(defaultPersonnel)

    useEffect(() => {
        if (data) {
            setPersonnel({
                name: data?.created_by_name,
                department: data?.request_department
            })
            const formDate = data.updated_at ? data.updated_at : data.created_at
            setDate({
                day: dayjs(formDate).format("DD"),
                month: dayjs(formDate).format("MM"),
                year: dayjs(formDate).format("YYYY")
            })

            //Get the currency code
            const isVND = data.payment_lines?.every((line) => line.currency_code === 'VND')
            //Then round the amount to 0 decimal if it's VND or 2 decimal if it's not
            data.request_amount = isVND ? Math.round(data.request_amount) : Number(data.request_amount).toFixed(2)
        }
    }, [data])

    const handlePrint = useReactToPrint({
        contentRef: printRef
    })

    const handleClose = () => {
        setOpen(false)
        //Reset the date to current date
        setDate({
            day: dayjs().format("DD"),
            month: dayjs().format("MM"),
            year: dayjs().format("YYYY")
        })
        setPrintMargin(defaultMargin)
        setPersonnel(defaultPersonnel)
    }

    const checkbox = (status) => {
        return <span style={{fontSize: '20pt'}}>{status ? '☑' : '☐'}</span>
    }

    const calcMargin = (margin) => {
        let result = marginPlaceholder
        if (Number(margin) - marginPlaceholder > 0) {
            result = Math.abs(marginPlaceholder - Number(margin)) + marginPlaceholder
        }

        return result
    }

    return (
        <Modal
            title={
                <Flex
                    justify='space-between'
                    align='center'
                >
                    <span>In đề nghị thanh toán</span>
                </Flex>
            }
            open={open}
            onCancel={handleClose}
            width={1000}
            footer={
                <Flex
                    justify='space-between'
                    className="mt-3"
                >
                    <Button
                        icon={<BorderTopOutlined/>}
                        title="Căn lề trang in"
                        onClick={() => setCustomMarginOpen(true)}
                    />
                    <Space>
                        <Button
                            icon={<CloseOutlined/>}
                            onClick={handleClose}
                        >
                            Đóng lại
                        </Button>
                        <Button
                            icon={<PrinterOutlined/>}
                            onClick={handlePrint}
                            type="primary"
                        >
                            Gửi ra máy in
                        </Button>
                    </Space>
                </Flex>
            }
        >
            <div
                ref={printRef}
                className="formFont form-draft-print"
                style={{
                    maxHeight: '60vh',
                    overflowY: 'auto'
                }}
            >
                <PrintStyle/>
                <div style={{
                    height: `${calcMargin(printMargin.top)}cm`,
                }}
                className="print-only"
                />
                <table
                    className="table1"
                >
                    <tbody>
                    <tr>
                        <td rowSpan="3" className="text-center" style={{width: '60%'}}>
                            <div className="mt-1">
                                <span style={{fontSize: '16pt', fontWeight: 'bold'}}>GIẤY ĐỀ NGHỊ THANH TOÁN</span><br/>
                                <Barcode
                                    value={data?.request_code || '0'}
                                    height={25} width={1.2}
                                    displayValue={true}
                                    fontSize={10}
                                    margin={3}
                                />
                            </div>
                        </td>
                        <td>Mã hiệu: 03-BM/TC/FL</td>
                    </tr>
                    <tr>
                        <td style={{width: '40%'}}>
                            Lần ban hành/Sửa đổi: 1/2
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: '40%'}}>
                            Ngày hiệu lực : 11/4/2024
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div className="w-100 text-center mt-2 text-bold formFont">
                    Ngày
                    <Input
                        className="date-changeable"
                        defaultValue={date.day}
                        value={date.day}
                        onChange={(e) => setDate({...date, day: e.target.value})}
                    />
                    tháng
                    <Input
                        className="date-changeable"
                        defaultValue={date.month}
                        value={date.month}
                        onChange={(e) => setDate({...date, month: e.target.value})}
                    />
                    năm
                    <Input
                        className="date-changeable year"
                        defaultValue={date.year}
                        value={date.year}
                        onChange={(e) => setDate({...date, year: e.target.value})}
                    />
                </div>

                <table className="table2 mt-3">
                    <tbody>
                    <tr>
                        <td>
                            <Flex
                                wrap={false}
                            >
                                <span className="header text-nowrap ">Họ tên người đề nghị:</span>
                                <Input
                                    className="date-changeable name"
                                    defaultValue={personnel.name}
                                />
                            </Flex>
                        </td>
                        <td>
                            <Flex
                                wrap={false}
                            >
                                <span className="header text-nowrap">Bộ phận:</span>
                                <Input
                                    className="date-changeable department"
                                    defaultValue={personnel.department}
                                />
                            </Flex>
                        </td>
                    </tr>
                    <tr>
                        <td><span className="header">Công ty:</span> {data?.transfer_from_account}</td>
                        <td><span className="header">Mã PO:</span> {data?.erp_code}</td>
                    </tr>
                    <tr>
                        <td><span className="header">Mã vụ việc:</span> {data?.special_code}</td>
                        <td><span
                            className="header">Ngày dự kiến chi:</span> {dayjs(data?.request_payment_date).format("DD/MM/YYYY")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <span className="header">
                                Đối tượng công nợ:&nbsp;
                            </span>
                            {data?.partner}
                        </td>
                    </tr>
                    </tbody>
                </table>

                <PaymentLineTable lineData={data?.payment_lines || lineData} print={true} showPrintButton={showPrintButton}/>

                <div className="mt-2">
                    <strong>Bằng chữ: </strong>{readNumber(data?.request_amount || 0)}
                </div>

                <div className="mt-2">
                    Phân loại thanh toán: {data?.instruction}<br/>
                    Tham chiếu chứng từ gốc: {data?.ref_document}<br/>
                    Hợp đồng số: {data?.ref_contract}<br/>
                    Hoá đơn số: {data?.ref_invoice}
                </div>

                <table className="table3">
                    <tbody>
                    <tr>
                        <td><strong>Hình thức thanh toán:</strong></td>
                        <td>
                            {checkbox(data?.transfer_from_account === 'TM')}
                            <span>Tiền mặt</span>
                        </td>
                        <td>{checkbox(data?.transfer_from_account !== 'TM')}
                            <span>CK từ TK {data?.transfer_from_account}</span></td>
                    </tr>
                    </tbody>
                </table>

                <div>
                    <span><strong>Thông tin chuyển khoản</strong><br/></span>
                    <span>Tên tài khoản: {data?.receiver_name}<br/></span>
                    <span>Số tài khoản: {data?.bank_account}</span>
                    <span className="ms-3">Tại ngân hàng: {data?.bank_name}</span>
                    <span className="ms-3">Tỉnh/TP: {data?.bank_province}</span>
                </div>

                <table className="table4">
                <tbody>
                    <tr>
                        <th>
                            TGĐ/GĐDH
                        </th>
                        <th>
                            GĐTC/TP Kế toán
                        </th>
                        <th>
                            GĐ Khối/Trưởng phòng
                        </th>
                        <th>
                            Người lập biểu
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <div style={{height: '130px'}}></div>
                        </td>
                        <td>
                        </td>
                        <td>
                            <div style={{
                                height: '130px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                alignContent: 'end',
                                width: '100%'
                            }}>
                                <Input
                                    defaultValue=""
                                    variant="borderless"
                                    className="text-center date-changeable name input-dotted-border"
                                />
                            </div>
                        </td>
                        <td>
                            <div style={{
                                height: '130px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                alignContent: 'end',
                                width: '100%'
                            }}>
                                <Input
                                    defaultValue={data?.created_by_name}
                                    variant="borderless"
                                    className="text-center date-changeable name input-dotted-border"
                                />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div style={{
                    height: `${calcMargin(printMargin.bottom)}cm`
                }}
                     className="print-only"
                />
            </div>
            <PageMargin open={customMarginOpen} setOpen={setCustomMarginOpen} margins={printMargin}
                        setMargins={setPrintMargin} defaultMargins={defaultMargin}/>
        </Modal>
    )
}

export default FormPrintPreview